<template>
  <router-link
    :to="{ name: 'categories' }"
    class=" block mb-3 text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
  >
    <i class="mr-2 pi pi-arrow-left"></i>
    Kembali
  </router-link>

  <div class="mb-5 card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Detail Kategori</h1>
    <div class="grid mb-5">
      <div class="col-12 md:col-4">
        <FormKit
          v-model="data"
          :type="uploadImage"
          name="image"
          label="Foto"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          :src="detail.image ? detail.image : require('@/assets/images/dummy.jpg')"
          :upload="updateServiceImage"
        />
      </div>
      <div class="col-0 md:col-1"></div>
      <div class="col-12 md:col-7">
        <h5 class="tw-text-lg tw-font-semibold">Detail</h5>
        <hr class="mt-2 mb-4" />
        <div class="grid mb-2" v-for="column in columns" :key="`_${column.field}`">
          <div class="col-6 md:col-4 opacity-60">
            {{ column.header }}
          </div>
          <div class="col-6 md:col-8 tw-font-medium">
            <div v-if="!column?.process">
              {{ detail[column.field] }}
            </div>
            <div v-else>{{ column.process(detail[column.field]) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <DashboardDetail
      header="Detail Kategori"
      :columns="columns"
      :data="detail"
      router-back-name="categories"
  >
    <template #columns>
      <FormKit
          v-model="data"
          :type="uploadImage"
          name="image"
          label="Foto"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          :src="detail.image"
          :upload="updateServiceImage"
      />
    </template>
  </DashboardDetail> -->
</template>

<script setup>
import {onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";

// import DashboardDetail from "@/components/dashboard/Detail";
import {useCategoryDetail, useCategoryForm} from "@/composables/category";
import {createInput} from "@formkit/vue";
import FormUploadImage from "@/components/form/FormUploadImage";
import {defaultFormikClass} from "@/constants/formik";

const data = ref(null)
const uploadImage = createInput(FormUploadImage, {
  props: ['src', 'upload'],
})

const route = useRoute()
const {detail, getDetail} = useCategoryDetail()
const {updateImage} = useCategoryForm()

const columns = [
  {field: "name", header: "Nama Kategori"},
]

const updateServiceImage = async (data) => {
  await updateImage(route.params.id, data)
  await getDetail(route.params.id)
}

onBeforeMount(async () => {
  await getDetail(route.params.id)
})
</script>
